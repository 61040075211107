import {
  SkeletonCircle,
  SkeletonRect,
  SkeletonWrapper,
} from "components/Skeleton/Skeleton";
import {
  ServerAlgoliaFetcherResponse,
  generateIndexName,
  serverAlgoliaFetcher,
} from "lib/algolia";
import { trackEvent } from "lib/amplitude";
import {
  getSubDomain,
  isBlueBirdURL,
  useIsSubdomain,
  useIsTenant,
} from "lib/host";
import { ArrayObjectSortBy, parseDataParams, ShuffleArray } from "lib/object";
import useInfiniteScroll from "lib/useInfiniteScroll";
import { useAuthStore } from "modules/Auth/authStore";
import { useCourseStore } from "modules/Course/courseStore";
import { GetServerSideProps } from "next";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";
import Section from "./Section";
import { motion } from "framer-motion";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { CONST } from "lib/const";
import Badge from "components/Badge";
import Image from "next/image";
import { useSearchStore } from "components/InstantSearchBox/searchStore";
import IconClose from "components/Icon/IconClose";
import { isBrowser } from "lib/ssr";
import { useAccessCourseTenant } from "modules/Explore/exploreApi";
import dynamic from "next/dynamic";
import { VolumeHighIcon } from "@vidstack/react/types/vidstack-react";
import { MoonLoader } from "react-spinners";
import { useIsDesktop } from "lib/device";
import Button from "components/Button";
import { Scene } from "@soulmachines/smwebsdk";
import Modal from "components/Modal";
import LoaderFadeInOut from "components/Loader/LoaderFadeInOut";
import type { StartAvatarResponse } from "@heygen/streaming-avatar";
import StreamingAvatar, {
  AvatarQuality,
  StreamingEvents,
} from "@heygen/streaming-avatar";
import { usePrevious } from "ahooks";
import { OpenAI } from "openai";
import { useJPTrackers } from "./homeTenantApi";
// import CardCourseWithLesson from "components/Card/CardCourseWithLesson";

const CardCourseWithLesson = dynamic(
  () =>
    import("components/Card/CardCourseWithLesson").then((mod) => mod.default),
  { ssr: false }
);

// export const getServerSideProps: GetServerSideProps<
//   ServerAlgoliaFetcherResponse
// > = async (context) => {
//   const q = typeof context.query.q == "string" ? context.query.q : "";

//   const page = parseInt(context.query.page as any)
//     ? parseInt(context.query.page as any)
//     : 0;

//   const categoryNames =
//     typeof context.query.categoryName == "string" && context.query.categoryName
//       ? context.query.categoryName.split(",")
//       : [];

//   const categoryFacetFilter = categoryNames.length
//     ? `${categoryNames.map((c) => `category:"${c}`).join(" OR ")}"`
//     : "";

//   const algoliaResponse = await serverAlgoliaFetcher(
//     q,
//     page,
//     categoryFacetFilter,
//     1000,
//     true
//   );

//   return {
//     props: {
//       __algoliaData: algoliaResponse.props?.__algoliaData ?? {},
//     },
//   };
// };

const HomeCard: React.FC<{
  data?: any;
  filters?: any;
  sortDesc?: any;
  searchText?: string;
  textValue?: string;
  sections?: any;
  isSquare?: boolean;
  isHidden?: any;
  keywordOthers?: any[];
  isKeywordsSearched?: string;
  handleReset?: any;
}> = ({
  data,
  filters,
  sortDesc,
  searchText,
  textValue,
  sections,
  isSquare = false,
  isHidden,
  keywordOthers,
  isKeywordsSearched,
  handleReset,
}) => {
  const isDesktop = useIsDesktop();
  const screen = typeof window !== "undefined" && window.innerWidth;
  const isMobile = screen < 770;
  const { t } = useTranslation("common");
  const { t: tCourse } = useTranslation("course");
  const [page, setPage] = useState(1);
  const [nbHits, setNbHits] = useState(null);
  const [indexName, setIndexName] = useState("");
  const domain = typeof window !== "undefined" && window.location.hostname;
  const { currentRecommend, totalFilter, setTotalFilter } = useSearchStore(
    (state) => ({
      currentRecommend: state.currentRecommend,
      totalFilter: state.totalFilter,
      setTotalFilter: state.setTotalFilter,
    })
  );
  const setRecommendData = useSearchStore((state) => state.setRecommendData);

  const router = useRouter();
  const PER_PAGE = 9;
  const [hasMore, setHasMore] = useState(true);
  const { loginState, currentUser } = useAuthStore((state) => ({
    loginState: state.loginState,
    currentUser: state.currentUser,
  }));
  const [emptyResults, setEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currData, setCurrData] = useState(null);

  // Load More
  const [visibleData, setVisibleData] = useState(
    currData?.slice(0, PER_PAGE) || []
  );
  const [hasMoreData, setHasMoreData] = useState(true);
  const observerRef = useRef<HTMLDivElement | null>(null);

  const loadMoreData = useCallback(() => {
    if (currData) {
      const nextData = currData.slice(
        visibleData.length,
        visibleData.length + PER_PAGE
      );

      setVisibleData((prev) => [...prev, ...nextData]);

      if (visibleData.length + nextData.length >= currData.length) {
        setHasMoreData(false);
      }
    }
  }, [currData, visibleData.length]);

  useEffect(() => {
    if (currData) {
      setVisibleData(currData.slice(0, PER_PAGE));
      setHasMoreData(true);
    }
  }, [currData]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMoreData) {
          loadMoreData();
        }
      },
      { threshold: 0.5 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [hasMoreData, loadMoreData]);
  // End Load More

  const [tempData, setTempData] = useState(null);
  const subDomain = isBrowser() ? getSubDomain(window.location.hostname) : null;

  const q = router?.query?.q ?? "";
  const isB2B = useIsTenant() || useIsSubdomain();
  const accessCourseApi = useAccessCourseTenant();
  const institutionNames =
    typeof router.query.institutionKey == "string" &&
    router.query.institutionKey
      ? router.query.institutionKey.split(",")
      : [];
  const institutionKeyFilter = institutionNames.length
    ? `${institutionNames.map((ins) => `institution:"${ins}"`).join(" OR ")}`
    : "";

  const tagsNames =
    typeof router.query.tagsKey == "string" && router.query.tagsKey
      ? router.query.tagsKey.split(",")
      : [];
  const tagsKeyFilter = tagsNames.length
    ? `${tagsNames.map((ins) => `tags:"${ins}"`).join(" OR ")}`
    : "";

  const sortByFilter =
    typeof router.query.sortBy == "string" ? router.query.sortBy : "";

  const getListCourses = (idxName: string) => {
    const allFilter = [institutionKeyFilter, tagsKeyFilter]
      .filter((a) => a)
      .join(" AND ");

    var itHasFilter =
      allFilter ||
      q ||
      (currentUser && currentUser?.referral === "interagroup") ||
      sortByFilter;

    const add_params =
      allFilter || q || (currentUser && currentUser?.referral === "interagroup")
        ? {
            q,
            facetFilters: allFilter,
          }
        : {
            q,
            facetFilters: allFilter,
          };

    const new_params = new URLSearchParams(
      parseDataParams({ ...add_params })
    ).toString();

    fetch(
      new_params
        ? `/api/search-algolia?indexName=${idxName}&${new_params}`
        : `/api/search-algolia?indexName=${idxName}`
    )
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(true);

        if (res?.data?.hits.length) {
          let algoliaHits = res.data.hits;

          /// Run normal function without checking
          /// allowed course or not when user is anonymous
          if (!currentUser?.referral) {
            const data = sortByFilter
              ? ArrayObjectSortBy(algoliaHits, sortByFilter, true)
              : q
              ? algoliaHits
              : ShuffleArray(algoliaHits);

            let currentData = null;
            if (page === 2) {
              const uniqueData = data.filter((item) => {
                return !tempData.some(
                  (existingItem) => existingItem.id === item.id
                );
              });
              currentData = itHasFilter
                ? [...data]
                : [...tempData, ...uniqueData];
            } else {
              currentData = data;
              setTempData(data);
            }
            let newArr = currentData;
            if (loginState !== "LOGIN") {
              newArr = currentData.filter((a) => !a.tenant);
            }

            const count = res?.data?.nbHits;
            setNbHits(count);
            setCurrData(newArr);
            setEmpty(newArr.length === 0);
          }

          /// Fetch access course API to get
          /// list of allowed course
          accessCourseApi.doFetch(
            {
              // @ts-ignore
              slug:
                window.location.hostname === "e-learning.bluebirdgroup.com"
                  ? "bluebird"
                  : currentUser?.referral,
            },
            {
              onSuccess(dataApi) {
                const listAccessCourses = dataApi?.data;
                const userId = currentUser?.id;
                const listForbiddenCourses = [];
                const listAllowedCourses = [];
                /// Generate forbidden course where
                /// userId != id from API
                listAccessCourses.forEach((course) => {
                  if (course.user != userId) {
                    const listCoursesSlug = course.courses?.map(
                      (course) => course.slug
                    );
                    listForbiddenCourses.push(...listCoursesSlug);
                  } else {
                    const listCoursesSlug = course.courses?.map(
                      (course) => course.slug
                    );
                    listAllowedCourses.push(...listCoursesSlug);
                  }
                });

                const listForbiddenCoursesAfterFilter =
                  listForbiddenCourses.filter(
                    (course) => !listAllowedCourses.includes(course)
                  );

                /// Hide course where it listed on forbidden courses
                if (listForbiddenCoursesAfterFilter.length > 0) {
                  const showedCourses = algoliaHits.filter((course) => {
                    // console.log("course hits:",course)
                    return !listForbiddenCoursesAfterFilter.includes(
                      course.slug
                    );
                  });
                  // console.log("showedCourses:", showedCourses);
                  algoliaHits = showedCourses;
                }

                const data = sortByFilter
                  ? ArrayObjectSortBy(algoliaHits, sortByFilter, true)
                  : q
                  ? algoliaHits
                  : ShuffleArray(algoliaHits);

                let currentData = null;
                if (page === 2) {
                  const uniqueData = data.filter((item) => {
                    return !tempData.some(
                      (existingItem) => existingItem.id === item.id
                    );
                  });
                  currentData = itHasFilter
                    ? [...data]
                    : [...tempData, ...uniqueData];
                } else {
                  currentData = data;
                  setTempData(data);
                }
                let newArr = currentData;
                if (loginState !== "LOGIN") {
                  newArr = currentData.filter((a) => !a.tenant);
                }

                setNbHits(newArr.length);
                setCurrData(newArr);
                setEmpty(newArr.length === 0);
              },
              onError(err) {
                const data = sortByFilter
                  ? ArrayObjectSortBy(algoliaHits, sortByFilter, true)
                  : q
                  ? algoliaHits
                  : ShuffleArray(algoliaHits);

                let currentData = null;
                if (page === 2) {
                  const uniqueData = data.filter((item) => {
                    return !tempData.some(
                      (existingItem) => existingItem.id === item.id
                    );
                  });
                  currentData = itHasFilter
                    ? [...data]
                    : [...tempData, ...uniqueData];
                } else {
                  currentData = data;
                  setTempData(data);
                }
                let newArr = currentData;
                if (loginState !== "LOGIN") {
                  newArr = currentData.filter((a) => !a.tenant);
                }

                setNbHits(newArr.length);
                setCurrData(newArr);
                setEmpty(newArr.length === 0);
              },
            }
          );
        } else {
          setNbHits(null);
          setCurrData([]);
          setEmpty(true);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      })
      .catch((err) => {
        setCurrData([]);
        setEmpty(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // console.log(currentRecommend);
    let nameOfIndex;
    /// Delete loginState check & dependency to reduce algolia call
    if (!isKeywordsSearched) {
      if (currentUser) {
        if (
          currentUser?.referral.includes("gaji-c") &&
          currentUser?.referral != "gaji-c0000117"
        ) {
          nameOfIndex = generateIndexName("gaji");
        } else if (
          currentUser?.referral.includes("gaji-c") &&
          currentUser?.referral == "gaji-c0000117"
        ) {
          nameOfIndex = generateIndexName(currentUser?.referral);
        } else {
          nameOfIndex = generateIndexName(
            window.location.hostname === "e-learning.bluebirdgroup.com"
              ? "bluebird"
              : currentUser?.referral == "bssn"
              ? "tapinkab"
              : currentUser?.referral
          );
        }
      } else {
        nameOfIndex = generateIndexName(
          window.location.hostname === "e-learning.bluebirdgroup.com"
            ? "bluebird"
            : subDomain == "bssn"
            ? "tapinkab"
            : subDomain
        );
        if (!["moodle", "example"].includes(nameOfIndex))
          getCourseRecommend(nameOfIndex);
      }

      if (!["moodle", "example"].includes(nameOfIndex)) {
        setIndexName(nameOfIndex);
        getListCourses(nameOfIndex);
      }
    } else {
      const nameOfIndex2 = generateIndexName(
        window.location.hostname === "e-learning.bluebirdgroup.com"
          ? "bluebird"
          : subDomain == "bssn"
          ? "tapinkab"
          : subDomain
      );
      if (!["moodle", "example"].includes(nameOfIndex2)) {
        setIndexName(nameOfIndex2);
        setCurrData([]);
        getListCourses(nameOfIndex2);
      }
    }
  }, [
    currentUser,
    q,
    institutionKeyFilter,
    tagsKeyFilter,
    sortByFilter,
    isKeywordsSearched,
  ]);

  const getCourseRecommend = (idxName) => {
    fetch(idxName ? `/api/search-algolia?indexName=${idxName}` : "/api/search")
      .then((res) => res.json())
      .then((res) => {
        const data =
          ArrayObjectSortBy(res.data?.hits, "created_date", true) ?? [];
        const recommendCourses = data.slice(0, 4);

        if (recommendCourses.length) {
          setRecommendData(recommendCourses);
        }
      });
  };

  const isMounted = useRef(false);

  const loadMore = useCallback(() => {
    if (page === 1) {
      setPage((p) => p + 1);
    }
  }, [currData]);

  useEffect(() => {
    if (indexName && !["moodle", "example"].includes(indexName)) {
      getListCourses(indexName);
    }
  }, [page]);

  useEffect(() => {
    const filter = sortByFilter != "" ? 1 : 0;
    const totalNewFilter = tagsNames.length + institutionNames.length + filter;
    if (totalFilter != totalNewFilter) {
      setTotalFilter(totalNewFilter);
    }
  }, [tagsNames, institutionNames]);

  const lastCourseElm = useInfiniteScroll(loadMore, hasMore, isLoading);
  const isAnyFiltered =
    tagsKeyFilter !== "" || institutionKeyFilter !== "" || q !== "";
  // const handleReset = () => {
  //   router.replace({
  //     pathname: "/",
  //   });
  // };

  const isLMS = router.pathname.includes("/course/[courseSlug]");
  const isBlueBirdTenant =
    typeof window !== "undefined" && isBlueBirdURL(window.location.hostname);

  return (
    <>
      {(isAnyFiltered || isKeywordsSearched) && !isLoading && nbHits && (
        <div className="pb-24 flex justify-start items-center px-16 md:px-0 gap-8">
          <div
            className="font-light items-center flex"
            dangerouslySetInnerHTML={{
              __html: t(
                tagsKeyFilter !== "" || institutionKeyFilter !== ""
                  ? "descriptionResultFilter"
                  : "descriptionResultSearch",
                {
                  count: nbHits,
                  text: `${
                    tagsKeyFilter !== "" || institutionKeyFilter !== ""
                      ? ""
                      : q || isKeywordsSearched
                  }`,
                  additionalS: currData && currData.length > 1 ? "s" : "",
                }
              ),
            }}
          ></div>
          <div
            onClick={() => {
              q
                ? router.push({
                    pathname: "/",
                  })
                : handleReset();
            }}
            className="cursor-pointer block"
          >
            <IconClose size={20} color="#551FC1" />
          </div>
        </div>
      )}

      {isAnyFiltered && currData && currData.length > 0 ? (
        <div className={"grid grid-cols-1 mb-24 gap-40"}>
          {visibleData.map((course, index) => {
            let myRef = null;
            if (
              currData.length === index + 1 &&
              currentUser &&
              currentUser?.referral !== "interagroup"
            ) {
              myRef = lastCourseElm;
            }

            return (
              <div ref={myRef} key={course.name + index}>
                <Suspense fallback={<SkeletonCircle />}>
                  <CardCourseWithLesson
                    {...course}
                    isRow
                    isSquare={isSquare}
                    tracker={{
                      event: "b2b_homepage_course_a",
                      property: {
                        course_name: course?.name,
                        course_slug: course?.slug,
                        institution_name: course?.institution?.name,
                        section: 3,
                        category_name: `Default`,
                        index,
                        is_mandatory_course: false,
                      },
                    }}
                  />
                </Suspense>
              </div>
            );
          })}
          {hasMoreData && (
            <div ref={observerRef} className="mt-10 flex justify-center">
              <MoonLoader size={isDesktop ? 45 : 20} />
            </div>
          )}
        </div>
      ) : (
        <>
          {!isBlueBirdTenant && (
            <>
              {!isLoading && currData && currData.length > 0 && (
                <div
                  className={
                    "grid-cols-3 xl-max:grid-cols-2 hg-max:!grid-cols-1 gap-20 justify-center grid"
                  }
                >
                  {visibleData.map((course, index) => {
                    let myRef = null;
                    if (
                      currData.length === index + 1 &&
                      currentUser &&
                      currentUser?.referral !== "interagroup"
                    ) {
                      myRef = lastCourseElm;
                    }

                    return (
                      <div key={course.name + index}>
                        <Suspense fallback={<SkeletonCircle />}>
                          <CardCourseWithLesson
                            {...course}
                            isSquare={isSquare}
                            tracker={{
                              event: "b2b_homepage_course_a",
                              property: {
                                course_name: course?.name,
                                course_slug: course?.slug,
                                institution_name: course?.institution?.name,
                                section: 3,
                                category_name: `Default`,
                                index,
                                is_mandatory_course: false,
                              },
                            }}
                          />
                        </Suspense>
                      </div>
                    );
                  })}
                </div>
              )}
              {hasMoreData && (
                <div ref={observerRef} className="mt-10 flex justify-center">
                  <MoonLoader size={isDesktop ? 45 : 20} />
                </div>
              )}
            </>
          )}
        </>
      )}

      {/* {!isLoading && currData && currData.length > 0 && (
        <div
          className={
            "grid grid-cols-3 xl-max:grid-cols-2 hg-max:!grid-cols-1 gap-20 justify-center"
          }
        >
          {currData.map((course, index) => {
            let myRef = null;
            if (
              currData.length === index + 1 &&
              currentUser &&
              currentUser?.referral !== "interagroup"
            ) {
              myRef = lastCourseElm;
            }

            return (
              <div
                ref={myRef}
                key={course.name + index}
                onClick={() => {
                  trackEvent({
                    event: "b2b_homepage_course_a",
                    property: {
                      course_name: course?.name,
                      course_slug: course?.slug,
                      institution_name: course?.institution?.name,
                      section: 3,
                      category_name: `Default`,
                      index,
                      is_mandatory_course: false,
                    },
                  });
                }}
              >
                <Link
                  href={
                    loginState == "LOGIN" && isLMS
                      ? {
                          pathname: "/r/[page]",
                          query: {
                            page: `/course/${course?.slug}`,
                          },
                        }
                      : loginState == "LOGIN"
                      ? {
                          pathname: "/course/[courseSlug]",
                          query: { courseSlug: course?.slug },
                        }
                      : {
                          pathname: "/login",
                          query: {
                            url: encodeURIComponent(`/course/${course?.slug}`),
                          },
                        }
                  }
                >
                  <CardWithVideo {...course} />
                </Link>
              </div>
            );
          })}
        </div>
      )} */}

      {currData &&
        currData.length === 0 &&
        (isLoading ? (
          <div
            className={
              "grid grid-cols-3 xl-max:grid-cols-2 hg-max:!grid-cols-1 gap-20 justify-center"
            }
          >
            {Array.from({ length: 9 }).map((el: number) => (
              <SkeletonRect
                key={el}
                className={`h-[270px] w-full ${!isSquare ? "rounded-8" : ""}`}
              />
            ))}
          </div>
        ) : (
          <div>
            <div className="pb-24 flex justify-start items-center px-16 md:px-0 gap-8 mt-16">
              <div
                className="font-light items-center flex"
                dangerouslySetInnerHTML={{
                  __html: t("descriptionResult"),
                }}
              ></div>
              <div onClick={handleReset} className="cursor-pointer block">
                <IconClose size={20} color="#551FC1" />
              </div>
            </div>

            <div>
              <Section
                tag={null}
                data={currentRecommend}
                title={
                  isAnyFiltered || isKeywordsSearched
                    ? tCourse("Rekomendasi Kursus Lainnya")
                    : ""
                }
                section={4}
                emoji="✨"
                id={null}
                isHidden={isHidden}
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default HomeCard;
